import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Collapse } from "reactstrap";
import { backendService } from "../../services/backend.service";
import { messageService } from "../../services/message.service";
import "./menuAside.scss";

import IcoHome from "../../assets/imgs/iconos/ico_home.png";
import IcoProfile from "../../assets/imgs/iconos/ico_profile.png";
import IcoSchedule from "../../assets/imgs/iconos/ico_schedule.png";
import IcoPoll from "../../assets/imgs/iconos/ico_poll.png";
import IcoAgreements from "../../assets/imgs/iconos/ico_agreements.png";
import IcoJob from "../../assets/imgs/iconos/ico_job.png";
import IcoOurCompany from "../../assets/imgs/iconos/ico_ourCompany.png";
import IcoPoliticsProcess from "../../assets/imgs/iconos/ico_politicsprocess.png";
import IcoAyuda from "../../assets/imgs/iconos/ico_ayuda.png";
import IcoAdmin from "../../assets/imgs/iconos/ico_admin.png";
import IcoLearningCenter from "../../assets/imgs/iconos/ico_learning_center.png";
import IcoBoard from "../../assets/imgs/iconos/ico_board.png"
import { useSelector } from "react-redux";
import { Legals } from "../legals/legals";
import IcoCaring from "../../assets/imgs/iconos/ico_caring.png";
import IcoDirectory from "../../assets/imgs/iconos/ico_directory.png";

export function MenuAside({ activeMenu, closeAll }) {
  const roleName = useSelector((state) => state.userSettings.roleName);
  const [isOpen, setIsOpen] = useState(false);
  const toggleCollapse = () => setIsOpen(!isOpen);
  const [messages, setMessages] = useState([]);

  const [widgets, setWidgets] = useState({
    surveyWidget: {},
    messageWidget: {},
    rewardWidget: {},
    courseWidget: {},
    providerWidget: [{ id: 0, logoCrls: "" }],
  });

  useEffect(() => {
    backendService.widgets().then((data) => {
      if (data.surveyWidget != null) {
        let date = new Date();
        date.setDate(date.getDate() + data.surveyWidget.expirationDays);
        data.surveyWidget.expirationDate = date;
      }
      setWidgets(data);
    });
    messageService
      .getMessage(0, "desc", "pending")
      .then((data) => setMessages(data));
  }, []);

  const MenuUsuario = () => {
    return (
      <div>
        <ul>
          <li>
            <NavLink className="menu_item" to="/home" onClick={closeAll}>
              <span className="ico_imgs">
                <img src={IcoHome} alt="Menu" />
              </span>
              <span>Muro</span>
            </NavLink>
          </li>
          <li>
            <NavLink className="menu_item" to="/profile" onClick={closeAll}>
              <span className="ico_imgs">
                <img src={IcoProfile} alt="Menu" />
              </span>
              <span>Mi Perfil</span>
            </NavLink>
          </li>
          {/* <li>
            <NavLink className="menu_item" to="/messages" onClick={closeAll}>
              <span className="ico_imgs">
                <img src={IcoMessage} alt="Menu" />
              </span>
              <span>
                Mensajes <span className="dots_notif">{messages.length}</span>
              </span>
            </NavLink>
          </li> */}
          <li>
            <NavLink className="menu_item" to="/diary" onClick={closeAll}>
              <span className="ico_imgs">
                <img src={IcoSchedule} alt="Menu" />
              </span>
              <span> Calendario </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              className="menu_item"
              to={`/our-company/1`}
              onClick={closeAll}
            >
              <span className="ico_imgs">
                <img src={IcoOurCompany} alt="Menu" />
              </span>
              <span>Nuestra Empresa</span>
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              className="menu_item"
              to={`/stories-pride/2`}
              onClick={closeAll}
            >
              <span className="ico_imgs">
                <img src={IcoOrgulloPride} alt="Menu" />
              </span>
              <span>Anuncios</span>
            </NavLink>
          </li> */}
          <li>
            <NavLink className="menu_item" to="/course-view" onClick={closeAll}>
              <span className="ico_imgs">
                <img src={IcoLearningCenter} alt="Menu" />
              </span>
              <span>
                 Learning Center{" "}
                {/* <span className="dots_notif">
                  {widgets.courseWidget ? widgets.courseWidget.quantity : "0"}
                </span> */}
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink className="menu_item" to="/policy-view" onClick={closeAll}>
              <span className="ico_imgs">
                <img src={IcoPoliticsProcess} alt="Menu" />
              </span>
              <span>
                 Políticas / Procesos{" "}
              </span>
            </NavLink>
          </li>
          {roleName == "Recursos Humanos" ?
         <li>
            <NavLink className="menu_item" to="/directory" onClick={closeAll}>
              <span className="ico_imgs">
                <img src={IcoDirectory} alt="Menu" />
              </span>
              <span>Soy Emprendedor</span>
            </NavLink>
          </li> : null }
           {/* <li>
            <NavLink className="menu_item" to="/ambassador" onClick={closeAll}>
              <span className="ico_imgs">
                <img src={IcoAmbassador} alt="Menu" />
              </span>
              <span>Representantes</span>
            </NavLink>
          </li>
          <li>
            <NavLink className="menu_item" to="/interest-group" onClick={closeAll}>
              <span className="ico_imgs">
                <img src={IcoInterestGroup} alt="Menu" />
              </span>
              <span>Grupos de Interés</span>
            </NavLink>
          </li>
          <li>
            <NavLink className="menu_item" to="/ecommerce" onClick={closeAll}>
              <span className="ico_imgs">
                <img src={IcoDiscount} alt="Menu" />
              </span>
              <span>Tiendas en Línea</span>
            </NavLink>
          </li> */}
          <li>
            <NavLink className="menu_item" to="/audience" onClick={closeAll}>
              <span className="ico_imgs">
                <img src={IcoAgreements} alt="Menu" />
              </span>
              <span>Convenios</span>
            </NavLink>
          </li>
          {/* <li>
            <NavLink className="menu_item" to="/discount" onClick={closeAll}>
              <span className="ico_imgs">
                <img src={IcoDiscount1} alt="Menu" />
              </span>
              <span>Descuentos</span>
            </NavLink>
          </li> 
          <li>
            <NavLink className="menu_item" to="/rewards" onClick={closeAll}>
              <span className="ico_imgs">
                <img src={IcoRewards} alt="Menu" />
              </span>
              <span>Recompensas</span>
            </NavLink>
          </li>            */}
          <li>
            <NavLink className="menu_item" to="/board" onClick={closeAll}>
              <span className="ico_imgs">
                <img src={IcoBoard} alt="Menu" />
              </span>
              <span>Reconocimientos</span>
            </NavLink>
          </li>
          {roleName == "Recursos Humanos" ?   
          <li>
            <NavLink
              className="menu_item"
              to="/caring-community"
              onClick={closeAll}
            >
              <span className="ico_imgs">
                <img src={IcoCaring} alt="Menu" />
              </span>
              <span>Comunidad Solidaria</span>
            </NavLink>
          </li> : null }
          {roleName == "Recursos Humanos" ?
          <li>
            <NavLink className="menu_item" to="/job-bank" onClick={closeAll}>
              <span className="ico_imgs">
                <img src={IcoJob} alt="Menu" />
              </span>
              <span>Bolsa de Trabajo</span>
            </NavLink>
          </li>
           : null}
          <li>
            <NavLink className="menu_item" to="/surveys" onClick={closeAll}>
              <span className="ico_imgs">
                <img src={IcoPoll} alt="Menu" />
              </span>
              <span>
                Encuestas{" "}
                <span className="dots_notif">
                  {widgets.surveyWidget ? widgets.surveyWidget.quantity : "0"}
                </span>
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink className="menu_item" to="/help" onClick={closeAll}>
              <span className="ico_imgs">
                <img src={IcoAyuda} alt="Menu" />
              </span>
              <span>Ayuda</span>
            </NavLink>
          </li>
        </ul>
      </div>
    );
  };

  const MenuOperaciones = () => {
    return (
      <ul>
        <li>
          <div className="menu_item" onClick={toggleCollapse}>
            <span className="ico_imgs">
              <img src={IcoAdmin} alt="Menu" />
            </span>
            <span>
              Operaciones <i className="fas fa-caret-down"></i>
            </span>
          </div>
          <Collapse isOpen={isOpen}>
            <ul className="ul_collapse">
              <li>
                <NavLink
                  className="submenu_item"
                  to="/AdminCompany"
                  onClick={closeAll}
                >
                  Empresas
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="submenu_item"
                  to="/controlreward/1"
                  onClick={closeAll}
                >
                  Recompensas
                </NavLink>
              </li>
            </ul>
          </Collapse>
          <hr />
        </li>
      </ul>
    );
  };

  const MenuAdministrador = () => {
    return (
      <ul>
        <li>
          <div className="menu_item" onClick={toggleCollapse}>
            <span className="ico_imgs">
              <img src={IcoAdmin} alt="Menu" />
            </span>
            <span>
              Administrador <i className="fas fa-caret-down"></i>
            </span>
          </div>
          <Collapse isOpen={isOpen}>
            <ul className="ul_collapse">
              <li>
                <NavLink
                  className="submenu_item"
                  to="/admin-diary"
                  onClick={closeAll}
                >
                  Calendario
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="submenu_item"
                  to="/control"
                  onClick={closeAll}
                >
                  Control
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="submenu_item"
                  to="/course"
                  onClick={closeAll}
                >
                  Learning Center
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="submenu_item"
                  to="/AdminSurvey"
                  onClick={closeAll}
                >
                  Encuestas
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="submenu_item"
                  to="/CompanyPost"
                  onClick={closeAll}
                >
                  Nuestra Empresa
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="submenu_item"
                  to="/admin-audience"
                  onClick={closeAll}
                >
                  Convenios
                </NavLink>
              </li>
              <li>
                 <NavLink className="submenu_item" to="/AdminBoard" onClick={closeAll}>Reconocimientos</NavLink>
              </li>
              {/* <li>
                <NavLink
                  className="submenu_item"
                  to="/product-filter"
                  onClick={closeAll}
                >
                  Recompensas
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  className="submenu_item"
                  to="/users"
                  onClick={closeAll}
                >
                  Usuarios
                </NavLink>
              </li>
              {roleName == "Recursos Humanos" ?
              <li>
                <NavLink
                  className="submenu_item"
                  to="/admin-caring-community"
                  onClick={closeAll}
                >
                  Comunidad Solidaria
                </NavLink>
              </li> : null }
              <li>
                <NavLink
                  className="submenu_item"
                  to="/admin-policy"
                  onClick={closeAll}
                >
                  Políticas / Procesos
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  className="submenu_item"
                  to="/admin-policy"
                  onClick={closeAll}
                >
                  Políticas / Procesos
                </NavLink>
              </li> */}
            </ul>
          </Collapse>
          <hr />
          <MenuUsuario />
        </li>
      </ul>
    );
  };

  return (
    <aside className={`wrapper_menu_aside ${activeMenu}`}>
      <nav className="nav_menu_items">
        {
          {
            Operaciones: <MenuOperaciones />,
            "Recursos Humanos": <MenuAdministrador />,
            Usuario: <MenuUsuario />,
          }[roleName]
        }
      </nav>
      <Legals />
    </aside>
  );
}
