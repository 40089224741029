import React, { useEffect, useState } from "react";
import { Label, Input } from "reactstrap";
import useInputForm from "../../../components/CustonHooks/FormHook";
import { SelectUsers } from "../../../components/Helpers/selectUsers";
import { AudienceUsers } from "../../../components/Helpers/audienceUsers";
import { useDiary } from "../../../services/diary.service";
import moment from "moment";
import swal from "sweetalert";
import { Alert } from "reactstrap";

export function EditDiary(props) {
  const [showError, setShowError] = useState(false);
  const [userSelec, setUserSelec] = useState([]);
  const [diaryItem, setDiaryItem] = useState({});
  const [selectedTypeId, setSelectedTypeId] = useState(0);
  const [btnSubmit,setBtnSubmit] = useState(false);

  const onSave = () => {
    setBtnSubmit(true)
    const regexNoSpaces = /^\S/;

    if (inputs.Name || inputs.Location || inputs.UrlString) {
      if (
        !regexNoSpaces.test(inputs.Name) ||
        !regexNoSpaces.test(inputs.Location) ||
        !regexNoSpaces.test(inputs.UrlString)
      ) {
        setShowError(true); 
        return;
      }
    }

    if (userSelec.length > 0) {
      if (inputs.CompanyDiaryId && inputs.CompanyDiaryId > 0) {
        var data = { ...inputs };
        data.TypeId = selectedTypeId;
        data.Repeat = parseInt(data.Repeat);
        data.StartDate = data.StartDate + "T" + data.StartTime;
        data.EndDate = data.EndDate + "T" + data.EndTime;
        data.Active = !data.CancelEvent;
        data.Users = userSelec;

        if (inputs.CancelEvent)
          swal({
            title: "¿Estás seguro que deseas cancelar este evento?",
            icon: "warning",
            dangerMode: true,
            buttons: ["Cancelar", "Si, aceptar"],
          }).then((willDelete) => {
            if (willDelete) {
              useDiary.updateCompanyDiary(data).then((result) => {
                if (props.onSave) {
                  props.onSave(result);
                  setBtnSubmit(false);
                }
              }).catch(res => setBtnSubmit(false));
            }
          });
        else {
          useDiary.updateCompanyDiary(data).then((result) => {
            if (props.onSave) {
              props.onSave(result);
              setBtnSubmit(false);
            }
          }).catch(res => setBtnSubmit(false));
        }
      } else {
        var data = { ...inputs };
        data.TypeId = selectedTypeId;
        data.Repeat = parseInt(data.Repeat);
        data.StartDate = data.StartDate + "T" + data.StartTime;
        data.EndDate = data.EndDate + "T" + data.EndTime;
        data.Users = userSelec;

        useDiary.createCompanyDiary(data).then((result) => {
          if (props.onSave) {
            props.onSave(result);
            setBtnSubmit(false)
          }
        }).catch(res => setBtnSubmit(false));
      }
      setUserSelec([]);
    } else {
      swal({ text: "Debes elegir al menos un usuario", icon: "error" })
      setBtnSubmit(false)
      return null;
    };

    setTimeout(() => {
      setDiaryItem({
        CompanyDiaryId: 0,
        Name: "",
        DiaryTypeId: 0,
        Location: "",
        UrlString: "",
        StartDate: "",
        EndDate: "",
        StartTime: "00:00",
        EndTime: "00:00",
        Repeat: 0,
        AllDay: false,
        DepartmentId: 0,
        JobRoleId: 0,
      });
    }, "1000");

    setShowError(false);
    return null;
  };

  const handleOnCancel = () => {
    if (props.onCancelEdit) props.onCancelEdit();
    setUserSelec([]);
  };

  const { handleSubmit, handleInputChange, inputs } = useInputForm(
    onSave,
    diaryItem
  );

  useEffect(() => {
    let newP = {};
    if (props.currentDiary) {
      newP = { ...props.currentDiary };
      newP.StartTime = moment(newP.StartDate).format("HH:mm");
      newP.EndTime = moment(newP.EndDate).format("HH:mm");
      newP.StartDate = moment(newP.StartDate).format("YYYY-MM-DD");
      newP.EndDate = moment(newP.EndDate).format("YYYY-MM-DD");
      newP.CancelEvent = false;
      setDiaryItem(newP);
    } else {
      setDiaryItem({
        CompanyDiaryId: 0,
        Name: "",
        DiaryTypeId: 0,
        Location: "",
        UrlString: "",
        StartDate: "",
        EndDate: "",
        StartTime: "00:00",
        EndTime: "00:00",
        Repeat: 0,
        AllDay: false,
        DepartmentId: 0,
        JobRoleId: 0,
      });
    }
  }, [props.currentDiary]);

  return (
    <form onSubmit={handleSubmit}>
      <span className="back_go" onClick={handleOnCancel}>
        <i className="fas fa-chevron-left"></i> Atrás
      </span>
      <fieldset className="mt-4 mb-5">
        <legend className="text fnt_medium">
          {inputs.CompanyDiaryId == 0 ? "Nuevo evento" : "Editar evento"}
        </legend>
        <Input
          type="hidden"
          name="CompanyDiaryId"
          onChange={handleInputChange}
          value={inputs.CompanyDiaryId}
        />
        <div className="row">
          <div className="col-sm-6 col-lg-4 mb-0">
            <div className="form-group">
              <Label htmlFor="Name">Título de evento</Label>
              <Input
                type="text"
                name="Name"
                placeholder="Título de evento"
                onChange={handleInputChange}
                value={inputs.Name}
                required
              />
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 mb-0">
            <div className="form-group">
              <Label htmlFor="Location">Ubicación</Label>
              <Input
                type="text"
                name="Location"
                placeholder="Ubicaci&oacute;n"
                onChange={handleInputChange}
                value={inputs.Location}
                required
              />
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 mb-0">
            <div className="form-group">
              <Label htmlFor="UrlString">Link</Label>
              <Input
                type="text"
                name="UrlString"
                placeholder="Link"
                onChange={handleInputChange}
                value={inputs.UrlString}
                required
              />
            </div>
          </div>
        </div>
        {showError && (
          <Alert color="danger" className="mt-0">
            <small>No se aceptan espacios en blanco al inicio</small>
          </Alert>
        )}
        <div className="row">
          <div className="col-sm-6 col-lg-4">
            <div className="form-group">
              <Label htmlFor="StartDate">Fecha de inicio</Label>
              <Input
                type="date"
                className="mb-2"
                name="StartDate"
                placeholder="Fecha inicial"
                onChange={handleInputChange}
                value={inputs.StartDate}
                min={moment(new Date() - 1).format("YYYY-MM-DD")}
              />
              <Input
                type="time"
                name="StartTime"
                placeholder="Hora inicial"
                onChange={handleInputChange}
                value={inputs.StartTime}
              />
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="form-group">
              <Label htmlFor="EndDate">Fecha de fin</Label>
              <Input
                type="date"
                className="mb-2"
                name="EndDate"
                placeholder="Fecha final"
                onChange={handleInputChange}
                value={inputs.EndDate}
              />
              <Input
                type="time"
                name="EndTime"
                placeholder="Hora final"
                onChange={handleInputChange}
                value={inputs.EndTime}
              />
            </div>
          </div>
          {inputs.CompanyDiaryId != 0 ? (
            <div className="col-sm-6 col-lg-4 mb-3">
              <label htmlFor="duration">Duración</label>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  onChange={handleInputChange}
                  checked={inputs.AllDay}
                  name="AllDay"
                  id="duration"
                />
                <label
                  className="custom-control-label cursor"
                  htmlFor="duration"
                >
                  Todo el día
                </label>
              </div>
              <label htmlFor="cancelEvent">Cancelar evento</label>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  onChange={handleInputChange}
                  checked={inputs.CancelEvent}
                  name="CancelEvent"
                  id="cancelEvent"
                />
                <label
                  className="custom-control-label cursor"
                  htmlFor="cancelEvent"
                >
                  Deseo cancelar este evento
                </label>
              </div>
            </div>
          ) : null}
          {/*<div className="col-md-4">
						<div className="form-group">
								<Label htmlFor="DiaryTypeId">Tipo</Label>
								<div><input type="radio" name="DiaryTypeId" value='0' checked={selectedTypeId === 0} onChange={(event) => handleOptionChange(event, 0)} />Evento </div>
								<div><input type="radio" name="DiaryTypeId" value='1' checked={selectedTypeId === 1} onChange={(event) => handleOptionChange(event, 1)} />Cumplea&ntilde;os </div>
						</div>
				</div>*/}

          {/*<div className="col-md-4">
						<div className="form-group">
								<Label htmlFor="Repeat">Repetir</Label>
								<Input type="select" name="Repeat" onChange={handleInputChange} value={inputs.Repeat}>
										<option value="">Seleccione...</option>
										<option value={1}>Evento &uacute;nico</option>
										<option value={2}>Semanalmente</option>
										<option value={3}>Mensualmente</option>
										<option value={4}>Anualmente</option>
								</Input>
						</div>
				</div>*/}
        </div>

        {inputs.CompanyDiaryId > 0 ? (
          <AudienceUsers
            targetId={inputs.CompanyDiaryId}
            eventType="Agenda"
            selectUser={setUserSelec}
          />
        ) : (
          <SelectUsers selectUser={setUserSelec} title={"Evento dirigido a:"} />
        )}
        <hr />
        <div>
          <button
            className="btn secundary ml-1 mr-1"
            type="button"
            onClick={handleOnCancel}
          >
            Cancelar
          </button>
          <input
            className="btn primary  ml-1 mr-1"
            type="submit"
            value="Guardar"
            disabled={btnSubmit}
          />
        </div>
      </fieldset>
    </form>
  );
}
